.primary {
  background-color: var(--sys-primary);
}
.primary-text {
  color: var(--sys-primary);
}
.on-primary {
  background-color: var(--sys-on-primary);
}
.on-primary-text {
  color: var(--sys-on-primary);
}
.primary-container {
  background-color: var(--sys-primary-container);
}
.primary-container-text {
  color: var(--sys-primary-container);
}
.on-primary-container {
  background-color: var(--sys-on-primary-container);
}
.on-primary-container-text {
  color: var(--sys-on-primary-container);
}
.primary-fixed {
  background-color: var(--sys-primary-fixed);
}
.primary-fixed-text {
  color: var(--sys-primary-fixed);
}
.on-primary-fixed {
  background-color: var(--sys-on-primary-fixed);
}
.on-primary-fixed-text {
  color: var(--sys-on-primary-fixed);
}
.primary-fixed-dim {
  background-color: var(--sys-primary-fixed-dim);
}
.primary-fixed-dim-text {
  color: var(--sys-primary-fixed-dim);
}
.on-primary-fixed-variant {
  background-color: var(--sys-on-primary-fixed-variant);
}
.on-primary-fixed-variant-text {
  color: var(--sys-on-primary-fixed-variant);
}
.secondary {
  background-color: var(--sys-secondary);
}
.secondary-text {
  color: var(--sys-secondary);
}
.on-secondary {
  background-color: var(--sys-on-secondary);
}
.on-secondary-text {
  color: var(--sys-on-secondary);
}
.secondary-container {
  background-color: var(--sys-secondary-container);
}
.secondary-container-text {
  color: var(--sys-secondary-container);
}
.on-secondary-container {
  background-color: var(--sys-on-secondary-container);
}
.on-secondary-container-text {
  color: var(--sys-on-secondary-container);
}
.secondary-fixed {
  background-color: var(--sys-secondary-fixed);
}
.secondary-fixed-text {
  color: var(--sys-secondary-fixed);
}
.on-secondary-fixed {
  background-color: var(--sys-on-secondary-fixed);
}
.on-secondary-fixed-text {
  color: var(--sys-on-secondary-fixed);
}
.secondary-fixed-dim {
  background-color: var(--sys-secondary-fixed-dim);
}
.secondary-fixed-dim-text {
  color: var(--sys-secondary-fixed-dim);
}
.on-secondary-fixed-variant {
  background-color: var(--sys-on-secondary-fixed-variant);
}
.on-secondary-fixed-variant-text {
  color: var(--sys-on-secondary-fixed-variant);
}
.tertiary {
  background-color: var(--sys-tertiary);
}
.tertiary-text {
  color: var(--sys-tertiary);
}
.on-tertiary {
  background-color: var(--sys-on-tertiary);
}
.on-tertiary-text {
  color: var(--sys-on-tertiary);
}
.tertiary-container {
  background-color: var(--sys-tertiary-container);
}
.tertiary-container-text {
  color: var(--sys-tertiary-container);
}
.on-tertiary-container {
  background-color: var(--sys-on-tertiary-container);
}
.on-tertiary-container-text {
  color: var(--sys-on-tertiary-container);
}
.tertiary-fixed {
  background-color: var(--sys-tertiary-fixed);
}
.tertiary-fixed-text {
  color: var(--sys-tertiary-fixed);
}
.on-tertiary-fixed {
  background-color: var(--sys-on-tertiary-fixed);
}
.on-tertiary-fixed-text {
  color: var(--sys-on-tertiary-fixed);
}
.tertiary-fixed-dim {
  background-color: var(--sys-tertiary-fixed-dim);
}
.tertiary-fixed-dim-text {
  color: var(--sys-tertiary-fixed-dim);
}
.on-tertiary-fixed-variant {
  background-color: var(--sys-on-tertiary-fixed-variant);
}
.on-tertiary-fixed-variant-text {
  color: var(--sys-on-tertiary-fixed-variant);
}
.error {
  background-color: var(--sys-error);
}
.error-text {
  color: var(--sys-error);
}
.on-error {
  background-color: var(--sys-on-error);
}
.on-error-text {
  color: var(--sys-on-error);
}
.error-container {
  background-color: var(--sys-error-container);
}
.error-container-text {
  color: var(--sys-error-container);
}
.on-error-container {
  background-color: var(--sys-on-error-container);
}
.on-error-container-text {
  color: var(--sys-on-error-container);
}
.outline {
  background-color: var(--sys-outline);
}
.outline-text {
  color: var(--sys-outline);
}
.background {
  background-color: var(--sys-background);
}
.background-text {
  color: var(--sys-background);
}
.on-background {
  background-color: var(--sys-on-background);
}
.on-background-text {
  color: var(--sys-on-background);
}
.surface {
  background-color: var(--sys-surface);
}
.surface-text {
  color: var(--sys-surface);
}
.on-surface {
  background-color: var(--sys-on-surface);
}
.on-surface-text {
  color: var(--sys-on-surface);
}
.surface-variant {
  background-color: var(--sys-surface-variant);
}
.surface-variant-text {
  color: var(--sys-surface-variant);
}
.on-surface-variant {
  background-color: var(--sys-on-surface-variant);
}
.on-surface-variant-text {
  color: var(--sys-on-surface-variant);
}
.inverse-surface {
  background-color: var(--sys-inverse-surface);
}
.inverse-surface-text {
  color: var(--sys-inverse-surface);
}
.inverse-on-surface {
  background-color: var(--sys-inverse-on-surface);
}
.inverse-on-surface-text {
  color: var(--sys-inverse-on-surface);
}
.inverse-primary {
  background-color: var(--sys-inverse-primary);
}
.inverse-primary-text {
  color: var(--sys-inverse-primary);
}
.shadow {
  background-color: var(--sys-shadow);
}
.shadow-text {
  color: var(--sys-shadow);
}
.surface-tint {
  background-color: var(--sys-surface-tint);
}
.surface-tint-text {
  color: var(--sys-surface-tint);
}
.outline-variant {
  background-color: var(--sys-outline-variant);
}
.outline-variant-text {
  color: var(--sys-outline-variant);
}
.scrim {
  background-color: var(--sys-scrim);
}
.scrim-text {
  color: var(--sys-scrim);
}
.surface-container-highest {
  background-color: var(--sys-surface-container-highest);
}
.surface-container-highest-text {
  color: var(--sys-surface-container-highest);
}
.surface-container-high {
  background-color: var(--sys-surface-container-high);
}
.surface-container-high-text {
  color: var(--sys-surface-container-high);
}
.surface-container {
  background-color: var(--sys-surface-container);
}
.surface-container-text {
  color: var(--sys-surface-container);
}
.surface-container-low {
  background-color: var(--sys-surface-container-low);
}
.surface-container-low-text {
  color: var(--sys-surface-container-low);
}
.surface-container-lowest {
  background-color: var(--sys-surface-container-lowest);
}
.surface-container-lowest-text {
  color: var(--sys-surface-container-lowest);
}
.surface-bright {
  background-color: var(--sys-surface-bright);
}
.surface-bright-text {
  color: var(--sys-surface-bright);
}
.surface-dim {
  background-color: var(--sys-surface-dim);
}
.surface-dim-text {
  color: var(--sys-surface-dim);
}
