.display-large{
  font-family: var(--sys-display-large-font);
  font-weight: var(--sys-display-large-weight);
  font-size: var(--sys-display-large-size);
  letter-spacing: var(--sys-display-large-tracking);
  line-height: var(--sys-display-large-line-height);
}
.display-medium{
  font-family: var(--sys-display-medium-font);
  font-weight: var(--sys-display-medium-weight);
  font-size: var(--sys-display-medium-size);
  letter-spacing: var(--sys-display-medium-tracking);
  line-height: var(--sys-display-medium-line-height);
}
.display-small{
  font-family: var(--sys-display-small-font);
  font-weight: var(--sys-display-small-weight);
  font-size: var(--sys-display-small-size);
  letter-spacing: var(--sys-display-small-tracking);
  line-height: var(--sys-display-small-line-height);
}
.headline-large{
  font-family: var(--sys-headline-large-font);
  font-weight: var(--sys-headline-large-weight);
  font-size: var(--sys-headline-large-size);
  letter-spacing: var(--sys-headline-large-tracking);
  line-height: var(--sys-headline-large-line-height);
}
.headline-medium{
  font-family: var(--sys-headline-medium-font);
  font-weight: var(--sys-headline-medium-weight);
  font-size: var(--sys-headline-medium-size);
  letter-spacing: var(--sys-headline-medium-tracking);
  line-height: var(--sys-headline-medium-line-height);
}
.headline-small{
  font-family: var(--sys-headline-small-font);
  font-weight: var(--sys-headline-small-weight);
  font-size: var(--sys-headline-small-size);
  letter-spacing: var(--sys-headline-small-tracking);
  line-height: var(--sys-headline-small-line-height);
}
.title-large {
  font-family: var(--sys-title-large-font);
  font-weight: var(--sys-title-large-weight);
  font-size: var(--sys-title-large-size);
  letter-spacing: var(--sys-title-large-tracking);
  line-height: var(--sys-title-large-line-height);
}

.title-medium {
  font-family: var(--sys-title-medium-font);
  font-weight: var(--sys-title-medium-weight);
  font-size: var(--sys-title-medium-size);
  letter-spacing: var(--sys-title-medium-tracking);
  line-height: var(--sys-title-medium-line-height);
}

.title-small {
  font-family: var(--sys-title-small-font);
  font-weight: var(--sys-title-small-weight);
  font-size: var(--sys-title-small-size);
  letter-spacing: var(--sys-title-small-tracking);
  line-height: var(--sys-title-small-line-height);
}
.body-large{
  font-family: var(--sys-body-large-font);
  font-weight: var(--sys-body-large-weight);
  font-size: var(--sys-body-large-size);
  letter-spacing: var(--sys-body-large-tracking);
  line-height: var(--sys-body-large-line-height);
}
.body-medium{
  font-family: var(--sys-body-medium-font);
  font-weight: var(--sys-body-medium-weight);
  font-size: var(--sys-body-medium-size);
  letter-spacing: var(--sys-body-medium-tracking);
  line-height: var(--sys-body-medium-line-height);
}
.body-small{
  font-family: var(--sys-body-small-font);
  font-weight: var(--sys-body-small-weight);
  font-size: var(--sys-body-small-size);
  letter-spacing: var(--sys-body-small-tracking);
  line-height: var(--sys-body-small-line-height);
}
.label-large{
  font-family: var(--sys-label-large-font);
  font-weight: var(--sys-label-large-weight);
  font-size: var(--sys-label-large-size);
  letter-spacing: var(--sys-label-large-tracking);
  line-height: var(--sys-label-large-line-height);
}
.label-medium{
  font-family: var(--sys-label-medium-font);
  font-weight: var(--sys-label-medium-weight);
  font-size: var(--sys-label-medium-size);
  letter-spacing: var(--sys-label-medium-tracking);
  line-height: var(--sys-label-medium-line-height);
}
.label-small{
  font-family: var(--sys-label-small-font);
  font-weight: var(--sys-label-small-weight);
  font-size: var(--sys-label-small-size);
  letter-spacing: var(--sys-label-small-tracking);
  line-height: var(--sys-label-small-line-height);
}
