
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// generated ad4znovin theme
@use 'm3-theme' as theme;

// include colors and typography module
@use 'colors';
@use 'typography';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// Define the theme object.

/*$ad4znovin-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));
*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {

  // When using system variables, remember to provide values for them
  // or uncomment the lines below to generate them from the theme.
  @include mat.system-level-colors(theme.$light-theme);
  @include mat.system-level-typography(theme.$light-theme);

  @include mat.all-component-themes(theme.$light-theme);


  // Apply the dark theme only when the user prefers dark themes.
  @media (prefers-color-scheme: dark) {

    @include mat.system-level-colors(theme.$dark-theme);

    @include mat.all-component-colors(theme.$dark-theme);

  }
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
@include mat.typography-hierarchy(theme.$light-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($ad4znovin-theme);
/* You can add global styles to this file, and also import other style files */



html, body {
  height: 100%;
  box-sizing: border-box;

}

body {
  margin: 0;
  background-color: var(--sys-background);
  color: var(--sys-on-background);
}

/* LAYOUT */

.spacer {
  flex: 1 1 auto;
}


/* TABS */

/* full height tab body */
.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

/* tab body content no overflow */
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.tab-icon {
  margin-right: 8px;
}

/* tablet */
@media (min-width:600px) {

  .tab-icon {
    display: none !important;
  }
}

.tab-label {
  padding-right: 4px;
}

/* mobile */
@media (max-width:599px) {

  .tab-label {
    display: none !important;
  }
}
